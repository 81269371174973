<template>
    <div>
      <div class="container my-5">
        <div class="card custom-shadow">
          <div class="card-body">
            <div class="row">
              <div class="col-12 col-lg-4 w-100">
                <itc-product-carousel :imgPath="product.imgPath || {primary: 'NoImg'}"></itc-product-carousel>
              </div>
              <div class="col-12 col-lg-8 mt-3 mt-lg-0">
                <div class="row">
                  <div class="col">
                    <div class="card-block">
                      <h4 class="card-title">{{product.name}}</h4>
                      <div v-if="product.price != null" class="product-price text-info">
                        ${{product.price}}
                      </div>
                      <hr>
                    </div>
                </div>
                </div>
                <div class="row">
                  <div class="col">
                    <p class="card-text"><strong>Descripción del producto</strong></p>
                    <p class="card-text">{{product.shortDesc}}</p>
                    <br>
                    <p class="card-text">{{product.description}}</p>
                  </div>
                </div>
              </div>        
            </div>
          </div>
        </div>
      </div>
      <div v-if="relatedProducts.length > 0" class="d-none d-lg-block text-center my-5 w-75 align-div">
        <h3 class="text-muted">Productos relacionados</h3>
        <hr>
        <div class="container h-50">
          <div class="carousel slide" data-ride="carousel" id="multi_item">
            <div class="carousel-inner">
              <div v-for="(products, i) in relatedProducts" :key="i" class="carousel-item" :class="{active: i == 0}">
                <div class="row similar-carousel align-div">
                  <itc-product v-for="(product, i) in products" :key="i" :product="product"></itc-product> 
                </div>
              </div>
            </div>
            <template v-if="relatedProducts.length > 3">
              <a class="carousel-control-prev" href="#multi_item" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only">Previous</span>
              </a>
              <a class="carousel-control-next" href="#multi_item" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
              </a>
            </template>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import axios from 'axios'

import ProductCarousel from '../../components/shop/ProductCarousel'
import Product from '../../components/shop/Product'

export default {
  data(){
    return {
      id: this.$route.params.id,
      product: {},
      relatedProducts: []
    }
  },
  //TODO: Hacer que los productos lleguen como props
  components: {
    'itc-product-carousel': ProductCarousel,
    'itc-product': Product
  },
  methods: {
    async loadProduct(){
      const resp = await axios.get(`/product/id?id=${this.id}&mode=related`)
      this.product = resp.data.product
      this.relatedProducts = resp.data.relatedProducts
      window.scrollTo(0, 0)
    }
  },
  watch: {
    async '$route'(to, from){
      this.id = to.params.id
      await this.loadProduct()
    }
  },
  async created(){
    await this.loadProduct()
  }
}
</script>

<style lang="scss" scoped>
.product-price {
  font-size: 1.5rem;
  font-weight: bolder;
}
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}
</style>
