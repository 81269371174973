<template>
    <div class="col-12 col-lg-4">
        <div class="card my-4 h-95 w-100 custom-shadow">
            <a href="#">
                <div class="text-center">
                    <router-link :to="`/shop/product/${product._id}`">
                        <img :src="`${publicPath}${product.imgPath.primary}`" class="card-img-top mt-2 w-75">
                    </router-link>
                </div> 
            </a>
            <hr>
            <div class="card-body">
                <div class="row h-25">
                    <div class="col-12">
                        <h5 class="card-title">{{product.name}}</h5>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 mt-5">
                        <p class="card-text">{{product.shortDesc}}</p>
                    </div>
                </div>
            </div>
            <div class="card-footer">
                <router-link :to="`/shop/product/${product._id}`">
                    <button 
                        @click="productClicked(product.name)"
                        class="btn btn-primary btn-block btn-sm">
                        <strong>Ver</strong>
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'

export default {
    data(){
        return {
            publicPath: process.env.VUE_APP_PUBLIC_PATH
        }
    },
    props: {
        product: Object
    },
    methods: {
        productClicked(name){
            axios.post('/diary-data/product-clicked', {productName: name})
        }
    }
}
</script>

<style lang="scss" scoped>
    .h-95{
        height: 95%
    }
    .productImg {
        cursor: pointer;
        //align: center;
    }
</style>