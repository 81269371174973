<template>
    <div class="carousel slide" id="carouselBig" data-ride="carousel">
        <div class="carousel-inner">
            <div class="carousel-item active">
                <img :src="`${publicPath}${imgPath.primary}`" class="img-modal img-big-carousel d-block">
            </div>
            <div class="carousel-item" v-if="imgPath.secondary">
                <img :src="`${publicPath}${imgPath.secondary}`" class="img-modal img-big-carousel d-block">
            </div>
            <div class="carousel-item" v-if="imgPath.tertiary">
                <img :src="`${publicPath}${imgPath.tertiary}`" class="img-modal img-big-carousel d-block">
            </div>
        </div>
        <template v-if="imgPath.secondary">
            <a class="carousel-control-prev" href="#carouselBig" role="button" data-slide="prev">
                <span class="carousel-control-prev-icon" aria-hidden="true"></span>
                <span class="sr-only"   >Previous</span>
            </a>
            <a class="carousel-control-next" href="#carouselBig" role="button" data-slide="next">
                <span class="carousel-control-next-icon" aria-hidden="true"></span>
                <span class="sr-only">Next</span>
            </a>
        </template>
    </div>
</template>

<script>
export default {
    data(){
        return {
            publicPath: process.env.VUE_APP_PUBLIC_PATH
        }
    },
    props: {
        imgPath: Object
    },
}
</script>

<style lang="scss" scoped>
.carousel-control-next-icon,
.carousel-control-prev-icon {
  filter: invert(1);
}
.img-small-carousel {
    width:70%;/* or to whatever you want here */
    height: auto; /* or to whatever you want here */
}
.img-big-carousel {
    width: 100%;/* or to whatever you want here */
    height: auto; /* or to whatever you want here */
}
</style>